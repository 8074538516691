import { Footer } from "../container/footer";
import { Navbar } from "../container/nabar";
import { HostingHeader } from "./hosting_header";
import HostingMain from "./hosting_main";

const Hostinger = () => {
    return (
        <>
            <Navbar />
            <HostingHeader />
            <HostingMain />
            <Footer />
        </>
    )
}

export default Hostinger;