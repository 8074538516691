/* eslint-disable react/jsx-pascal-case */
import { Cat_area } from "../container/cat_area";
import { Footer } from "../container/footer";
import { Navbar } from "../container/nabar";
export const About = () => {
	return (
		<>
			<Navbar />
			<Cat_area />
			<Footer />
		</>
	);
};
